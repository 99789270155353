
// @ts-nocheck


export const localeCodes =  [
  "fr",
  "en",
  "pt-BR"
]

export const localeLoaders = {
  "fr": [{ key: "../lang/fr-FR.json", load: () => import("../lang/fr-FR.json" /* webpackChunkName: "locale__tmp_build_38fa1549_lang_fr_FR_json" */), cache: true },
{ key: "../lang/fr-FR/flash.json", load: () => import("../lang/fr-FR/flash.json" /* webpackChunkName: "locale__tmp_build_38fa1549_lang_fr_FR_flash_json" */), cache: true },
{ key: "../lang/fr-FR/errors.json", load: () => import("../lang/fr-FR/errors.json" /* webpackChunkName: "locale__tmp_build_38fa1549_lang_fr_FR_errors_json" */), cache: true }],
  "en": [{ key: "../lang/en-GB.json", load: () => import("../lang/en-GB.json" /* webpackChunkName: "locale__tmp_build_38fa1549_lang_en_GB_json" */), cache: true },
{ key: "../lang/en-GB/flash.json", load: () => import("../lang/en-GB/flash.json" /* webpackChunkName: "locale__tmp_build_38fa1549_lang_en_GB_flash_json" */), cache: true },
{ key: "../lang/en-GB/errors.json", load: () => import("../lang/en-GB/errors.json" /* webpackChunkName: "locale__tmp_build_38fa1549_lang_en_GB_errors_json" */), cache: true }],
  "pt-BR": [{ key: "../lang/pt-BR.json", load: () => import("../lang/pt-BR.json" /* webpackChunkName: "locale__tmp_build_38fa1549_lang_pt_BR_json" */), cache: true },
{ key: "../lang/pt-BR/flash.json", load: () => import("../lang/pt-BR/flash.json" /* webpackChunkName: "locale__tmp_build_38fa1549_lang_pt_BR_flash_json" */), cache: true },
{ key: "../lang/pt-BR/errors.json", load: () => import("../lang/pt-BR/errors.json" /* webpackChunkName: "locale__tmp_build_38fa1549_lang_pt_BR_errors_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../config/vue-i18n.ts?hash=c617df9b&config=1" /* webpackChunkName: "__config_vue_i18n_ts_c617df9b" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./config/vue-i18n.ts",
  "locales": [
    {
      "code": "fr",
      "iso": "fr-FR",
      "dateFormat": "dd/MM/yyyy",
      "iconComponent": "IFrenchFlag",
      "emoji": "🇫🇷",
      "switchText": "Français",
      "files": [
        "lang/fr-FR.json",
        "lang/fr-FR/flash.json",
        "lang/fr-FR/errors.json"
      ]
    },
    {
      "code": "en",
      "iso": "en-GB",
      "iconComponent": "IEnglishFlag",
      "dateFormat": "MM/dd/yyyy",
      "emoji": "🇬🇧",
      "switchText": "English",
      "files": [
        "lang/en-GB.json",
        "lang/en-GB/flash.json",
        "lang/en-GB/errors.json"
      ]
    },
    {
      "code": "pt-BR",
      "iso": "pt-BR",
      "iconComponent": "IBrazilFlag",
      "dateFormat": "dd/MM/yyyy",
      "emoji": "🇧🇷",
      "switchText": "Português",
      "files": [
        "lang/pt-BR.json",
        "lang/pt-BR/flash.json",
        "lang/pt-BR/errors.json"
      ]
    }
  ],
  "defaultLocale": "pt-BR",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": true,
  "langDir": "lang/",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": false
  },
  "differentDomains": false,
  "baseUrl": "https://travel-br.swile.co",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "auth/logout": false
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "fr",
    "iso": "fr-FR",
    "dateFormat": "dd/MM/yyyy",
    "iconComponent": "IFrenchFlag",
    "emoji": "🇫🇷",
    "switchText": "Français",
    "files": [
      {
        "path": "lang/fr-FR.json"
      },
      {
        "path": "lang/fr-FR/flash.json"
      },
      {
        "path": "lang/fr-FR/errors.json"
      }
    ]
  },
  {
    "code": "en",
    "iso": "en-GB",
    "iconComponent": "IEnglishFlag",
    "dateFormat": "MM/dd/yyyy",
    "emoji": "🇬🇧",
    "switchText": "English",
    "files": [
      {
        "path": "lang/en-GB.json"
      },
      {
        "path": "lang/en-GB/flash.json"
      },
      {
        "path": "lang/en-GB/errors.json"
      }
    ]
  },
  {
    "code": "pt-BR",
    "iso": "pt-BR",
    "iconComponent": "IBrazilFlag",
    "dateFormat": "dd/MM/yyyy",
    "emoji": "🇧🇷",
    "switchText": "Português",
    "files": [
      {
        "path": "lang/pt-BR.json"
      },
      {
        "path": "lang/pt-BR/flash.json"
      },
      {
        "path": "lang/pt-BR/errors.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
