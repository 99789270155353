import { default as admins8MwZgpBxvPMeta } from "/tmp/build_38fa1549/pages/administration/admins.vue?macro=true";
import { default as _91id_93Snfyr0jTBtMeta } from "/tmp/build_38fa1549/pages/administration/billing/[id].vue?macro=true";
import { default as indexPwgadRPd3DMeta } from "/tmp/build_38fa1549/pages/administration/billing/index.vue?macro=true";
import { default as bookings5LK3ZOOSfUMeta } from "/tmp/build_38fa1549/pages/administration/bookings.vue?macro=true";
import { default as ecostatisticsLj1Vi3TBiEMeta } from "/tmp/build_38fa1549/pages/administration/ecostatistics.vue?macro=true";
import { default as exportshvzqAOzrbqMeta } from "/tmp/build_38fa1549/pages/administration/exports.vue?macro=true";
import { default as fidelityYnmuAgMTzYMeta } from "/tmp/build_38fa1549/pages/administration/fidelity.vue?macro=true";
import { default as invoicesgbtCmBwbnfMeta } from "/tmp/build_38fa1549/pages/administration/invoices.vue?macro=true";
import { default as policyzpZFdWUxahMeta } from "/tmp/build_38fa1549/pages/administration/organization/policy.vue?macro=true";
import { default as _91id_93BcXVxAniuCMeta } from "/tmp/build_38fa1549/pages/administration/payment/[id].vue?macro=true";
import { default as index2f6W3WLRD4Meta } from "/tmp/build_38fa1549/pages/administration/payment/index.vue?macro=true";
import { default as statisticsj7YEVQwVApMeta } from "/tmp/build_38fa1549/pages/administration/statistics.vue?macro=true";
import { default as indexgDHx34KAjLMeta } from "/tmp/build_38fa1549/pages/administration/teams/[teamId]/index.vue?macro=true";
import { default as invitationY1M3AhwbMaMeta } from "/tmp/build_38fa1549/pages/administration/teams/[teamId]/invitation.vue?macro=true";
import { default as indexBY5s1dHWxEMeta } from "/tmp/build_38fa1549/pages/administration/teams/[teamId]/policy/index.vue?macro=true";
import { default as modify8kzjr7VURfMeta } from "/tmp/build_38fa1549/pages/administration/teams/[teamId]/policy/modify.vue?macro=true";
import { default as validatorstH9MOK1hQ6Meta } from "/tmp/build_38fa1549/pages/administration/teams/[teamId]/validators.vue?macro=true";
import { default as _91teamId_93qy27pWX8G5Meta } from "/tmp/build_38fa1549/pages/administration/teams/[teamId].vue?macro=true";
import { default as index8puMuaUobCMeta } from "/tmp/build_38fa1549/pages/administration/teams/index.vue?macro=true";
import { default as trip_45labelsn59i7ErycUMeta } from "/tmp/build_38fa1549/pages/administration/trip-labels.vue?macro=true";
import { default as administrationGAz0zkRRQrMeta } from "/tmp/build_38fa1549/pages/administration.vue?macro=true";
import { default as swileUZWKOJOJVjMeta } from "/tmp/build_38fa1549/pages/auth/swile.vue?macro=true";
import { default as indexzruB43qY5jMeta } from "/tmp/build_38fa1549/pages/bookings/index.vue?macro=true";
import { default as indexuxnrTSELHwMeta } from "/tmp/build_38fa1549/pages/bookings/validator/index.vue?macro=true";
import { default as pendingfAUfV6tr29Meta } from "/tmp/build_38fa1549/pages/bookings/validator/pending.vue?macro=true";
import { default as _91id_93nOzIjP7FWEMeta } from "/tmp/build_38fa1549/pages/car/[id].vue?macro=true";
import { default as exchangevtxneoxWbqMeta } from "/tmp/build_38fa1549/pages/flight/[id]/exchange.vue?macro=true";
import { default as faresXfCmxtvleMMeta } from "/tmp/build_38fa1549/pages/flight/[id]/fares.vue?macro=true";
import { default as indexBXnxGkkjX5Meta } from "/tmp/build_38fa1549/pages/flight/[id]/index.vue?macro=true";
import { default as forgotHLDkXxEoFvMeta } from "/tmp/build_38fa1549/pages/forgot.vue?macro=true";
import { default as get_45swile_45appeGWBfgmAAkMeta } from "/tmp/build_38fa1549/pages/get-swile-app.vue?macro=true";
import { default as helpMeCzPNA8XWMeta } from "/tmp/build_38fa1549/pages/help.vue?macro=true";
import { default as _91id_93uLWs50U3fpMeta } from "/tmp/build_38fa1549/pages/hotel/[id].vue?macro=true";
import { default as _91id_93CN7UWXVOWdMeta } from "/tmp/build_38fa1549/pages/impersonate/[id].vue?macro=true";
import { default as indexaTnA6yqXcsMeta } from "/tmp/build_38fa1549/pages/index.vue?macro=true";
import { default as _91suffix_93egGHFCMeb8Meta } from "/tmp/build_38fa1549/pages/invitation/[suffix].vue?macro=true";
import { default as _91token_934nZC2wXiUSMeta } from "/tmp/build_38fa1549/pages/invite/[token].vue?macro=true";
import { default as _91id_93y29LRr04onMeta } from "/tmp/build_38fa1549/pages/journeys/[id].vue?macro=true";
import { default as indexke7Zkz8xhDMeta } from "/tmp/build_38fa1549/pages/journeys/index.vue?macro=true";
import { default as membersJgi0tXXhrfMeta } from "/tmp/build_38fa1549/pages/myteam/members.vue?macro=true";
import { default as policyQZmC2rvCU2Meta } from "/tmp/build_38fa1549/pages/myteam/policy.vue?macro=true";
import { default as validators6EqgVXZONaMeta } from "/tmp/build_38fa1549/pages/myteam/validators.vue?macro=true";
import { default as myteamS4w3XFa04xMeta } from "/tmp/build_38fa1549/pages/myteam.vue?macro=true";
import { default as new_45usermioX2Bp9pHMeta } from "/tmp/build_38fa1549/pages/new-user.vue?macro=true";
import { default as passwordzjrVWmszBoMeta } from "/tmp/build_38fa1549/pages/password.vue?macro=true";
import { default as indexbx385ZvCXeMeta } from "/tmp/build_38fa1549/pages/search/car/[search_id]/index.vue?macro=true";
import { default as index7zcGdwmxcYMeta } from "/tmp/build_38fa1549/pages/search/car/index.vue?macro=true";
import { default as _91search_id_93DU0FDvFRfbMeta } from "/tmp/build_38fa1549/pages/search/car/init/[search_id].vue?macro=true";
import { default as _91search_id_93gMWqnr4hODMeta } from "/tmp/build_38fa1549/pages/search/flight/[search_id].vue?macro=true";
import { default as indexOAJPdjm4c1Meta } from "/tmp/build_38fa1549/pages/search/flight/index.vue?macro=true";
import { default as index2wXZ5RlE5WMeta } from "/tmp/build_38fa1549/pages/search/hotel/[search_id]/index.vue?macro=true";
import { default as _91hotelIds_93YYW7x0I360Meta } from "/tmp/build_38fa1549/pages/search/hotel/[search_id]/rooms/[hotelIds].vue?macro=true";
import { default as indexIJkeaAkJfuMeta } from "/tmp/build_38fa1549/pages/search/hotel/index.vue?macro=true";
import { default as _91search_id_93ZZraLjb8rmMeta } from "/tmp/build_38fa1549/pages/search/train/[search_id].vue?macro=true";
import { default as indexOocn2WLPlkMeta } from "/tmp/build_38fa1549/pages/search/train/index.vue?macro=true";
import { default as searchPtsixgRXFvMeta } from "/tmp/build_38fa1549/pages/search.vue?macro=true";
import { default as indexQgFeg6EFBiMeta } from "/tmp/build_38fa1549/pages/teams/[teamId]/index.vue?macro=true";
import { default as invitationIj8GDi0S8wMeta } from "/tmp/build_38fa1549/pages/teams/[teamId]/invitation.vue?macro=true";
import { default as policyfy3ziVLyDFMeta } from "/tmp/build_38fa1549/pages/teams/[teamId]/policy.vue?macro=true";
import { default as validators1WgMJ2mCTRMeta } from "/tmp/build_38fa1549/pages/teams/[teamId]/validators.vue?macro=true";
import { default as _91teamId_937Cbmcr169iMeta } from "/tmp/build_38fa1549/pages/teams/[teamId].vue?macro=true";
import { default as indexDx4fjWbnPOMeta } from "/tmp/build_38fa1549/pages/teams/index.vue?macro=true";
import { default as teams1jLrMaETEdMeta } from "/tmp/build_38fa1549/pages/teams.vue?macro=true";
import { default as indexW0g4nTOOUPMeta } from "/tmp/build_38fa1549/pages/train/[id]/cancel/index.vue?macro=true";
import { default as indexKKz7bK1uk1Meta } from "/tmp/build_38fa1549/pages/train/[id]/exchange/changeable-selection/index.vue?macro=true";
import { default as indexODCJ3uGTtCMeta } from "/tmp/build_38fa1549/pages/train/[id]/exchange/section-selection/index.vue?macro=true";
import { default as indexgI23YBiGLpMeta } from "/tmp/build_38fa1549/pages/train/[id]/exchange/train-selection/index.vue?macro=true";
import { default as indexxNMld4J7K0Meta } from "/tmp/build_38fa1549/pages/train/[id]/index.vue?macro=true";
import { default as driver_45licenseD59OoSPPbzMeta } from "/tmp/build_38fa1549/pages/users/[id]/driver-license.vue?macro=true";
import { default as fidelityLZiTBNKtfTMeta } from "/tmp/build_38fa1549/pages/users/[id]/fidelity.vue?macro=true";
import { default as indexiRNvQjIvxQMeta } from "/tmp/build_38fa1549/pages/users/[id]/index.vue?macro=true";
import { default as passport1SekK76yAiMeta } from "/tmp/build_38fa1549/pages/users/[id]/passport.vue?macro=true";
import { default as passwordFrDO8vUXjhMeta } from "/tmp/build_38fa1549/pages/users/[id]/password.vue?macro=true";
import { default as _91id_93cN5BrZJ8kmMeta } from "/tmp/build_38fa1549/pages/users/[id].vue?macro=true";
import { default as indexlQbacnk7CuMeta } from "/tmp/build_38fa1549/pages/users/index.vue?macro=true";
import { default as vouchersD3MUETZHslMeta } from "/tmp/build_38fa1549/pages/vouchers.vue?macro=true";
import { default as component_45stub5QgS0k4WJEMeta } from "/tmp/build_38fa1549/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub5QgS0k4WJE } from "/tmp/build_38fa1549/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "administration",
    path: "/administration",
    meta: administrationGAz0zkRRQrMeta || {},
    component: () => import("/tmp/build_38fa1549/pages/administration.vue").then(m => m.default || m),
    children: [
  {
    name: "administration-admins",
    path: "admins",
    component: () => import("/tmp/build_38fa1549/pages/administration/admins.vue").then(m => m.default || m)
  },
  {
    name: "administration-billing-id",
    path: "billing/:id()",
    component: () => import("/tmp/build_38fa1549/pages/administration/billing/[id].vue").then(m => m.default || m)
  },
  {
    name: "administration-billing",
    path: "billing",
    component: () => import("/tmp/build_38fa1549/pages/administration/billing/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-bookings",
    path: "bookings",
    component: () => import("/tmp/build_38fa1549/pages/administration/bookings.vue").then(m => m.default || m)
  },
  {
    name: "administration-ecostatistics",
    path: "ecostatistics",
    component: () => import("/tmp/build_38fa1549/pages/administration/ecostatistics.vue").then(m => m.default || m)
  },
  {
    name: "administration-exports",
    path: "exports",
    component: () => import("/tmp/build_38fa1549/pages/administration/exports.vue").then(m => m.default || m)
  },
  {
    name: "administration-fidelity",
    path: "fidelity",
    component: () => import("/tmp/build_38fa1549/pages/administration/fidelity.vue").then(m => m.default || m)
  },
  {
    name: "administration-invoices",
    path: "invoices",
    component: () => import("/tmp/build_38fa1549/pages/administration/invoices.vue").then(m => m.default || m)
  },
  {
    name: "administration-organization-policy",
    path: "organization/policy",
    component: () => import("/tmp/build_38fa1549/pages/administration/organization/policy.vue").then(m => m.default || m)
  },
  {
    name: "administration-payment-id",
    path: "payment/:id()",
    component: () => import("/tmp/build_38fa1549/pages/administration/payment/[id].vue").then(m => m.default || m)
  },
  {
    name: "administration-payment",
    path: "payment",
    component: () => import("/tmp/build_38fa1549/pages/administration/payment/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-statistics",
    path: "statistics",
    component: () => import("/tmp/build_38fa1549/pages/administration/statistics.vue").then(m => m.default || m)
  },
  {
    name: _91teamId_93qy27pWX8G5Meta?.name,
    path: "teams/:teamId()",
    component: () => import("/tmp/build_38fa1549/pages/administration/teams/[teamId].vue").then(m => m.default || m),
    children: [
  {
    name: "administration-teams-teamId",
    path: "",
    meta: indexgDHx34KAjLMeta || {},
    component: () => import("/tmp/build_38fa1549/pages/administration/teams/[teamId]/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-teams-teamId-invitation",
    path: "invitation",
    component: () => import("/tmp/build_38fa1549/pages/administration/teams/[teamId]/invitation.vue").then(m => m.default || m)
  },
  {
    name: "administration-teams-teamId-policy",
    path: "policy",
    component: () => import("/tmp/build_38fa1549/pages/administration/teams/[teamId]/policy/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-teams-teamId-policy-modify",
    path: "policy/modify",
    meta: modify8kzjr7VURfMeta || {},
    component: () => import("/tmp/build_38fa1549/pages/administration/teams/[teamId]/policy/modify.vue").then(m => m.default || m)
  },
  {
    name: "administration-teams-teamId-validators",
    path: "validators",
    component: () => import("/tmp/build_38fa1549/pages/administration/teams/[teamId]/validators.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "administration-teams",
    path: "teams",
    component: () => import("/tmp/build_38fa1549/pages/administration/teams/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-trip-labels",
    path: "trip-labels",
    component: () => import("/tmp/build_38fa1549/pages/administration/trip-labels.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "auth-swile",
    path: "/auth/swile",
    meta: swileUZWKOJOJVjMeta || {},
    component: () => import("/tmp/build_38fa1549/pages/auth/swile.vue").then(m => m.default || m)
  },
  {
    name: "bookings",
    path: "/bookings",
    component: () => import("/tmp/build_38fa1549/pages/bookings/index.vue").then(m => m.default || m)
  },
  {
    name: "bookings-validator",
    path: "/bookings/validator",
    meta: indexuxnrTSELHwMeta || {},
    component: () => import("/tmp/build_38fa1549/pages/bookings/validator/index.vue").then(m => m.default || m)
  },
  {
    name: "bookings-validator-pending",
    path: "/bookings/validator/pending",
    meta: pendingfAUfV6tr29Meta || {},
    component: () => import("/tmp/build_38fa1549/pages/bookings/validator/pending.vue").then(m => m.default || m)
  },
  {
    name: "car-id",
    path: "/car/:id()",
    component: () => import("/tmp/build_38fa1549/pages/car/[id].vue").then(m => m.default || m)
  },
  {
    name: "flight-id-exchange",
    path: "/flight/:id()/exchange",
    component: () => import("/tmp/build_38fa1549/pages/flight/[id]/exchange.vue").then(m => m.default || m)
  },
  {
    name: "flight-id-fares",
    path: "/flight/:id()/fares",
    component: () => import("/tmp/build_38fa1549/pages/flight/[id]/fares.vue").then(m => m.default || m)
  },
  {
    name: "flight-id",
    path: "/flight/:id()",
    component: () => import("/tmp/build_38fa1549/pages/flight/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "forgot",
    path: "/forgot",
    meta: forgotHLDkXxEoFvMeta || {},
    component: () => import("/tmp/build_38fa1549/pages/forgot.vue").then(m => m.default || m)
  },
  {
    name: "get-swile-app",
    path: "/get-swile-app",
    meta: get_45swile_45appeGWBfgmAAkMeta || {},
    component: () => import("/tmp/build_38fa1549/pages/get-swile-app.vue").then(m => m.default || m)
  },
  {
    name: "help",
    path: "/help",
    component: () => import("/tmp/build_38fa1549/pages/help.vue").then(m => m.default || m)
  },
  {
    name: "hotel-id",
    path: "/hotel/:id()",
    component: () => import("/tmp/build_38fa1549/pages/hotel/[id].vue").then(m => m.default || m)
  },
  {
    name: "impersonate-id",
    path: "/impersonate/:id()",
    component: () => import("/tmp/build_38fa1549/pages/impersonate/[id].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexaTnA6yqXcsMeta || {},
    component: () => import("/tmp/build_38fa1549/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invitation-suffix",
    path: "/invitation/:suffix()",
    meta: _91suffix_93egGHFCMeb8Meta || {},
    component: () => import("/tmp/build_38fa1549/pages/invitation/[suffix].vue").then(m => m.default || m)
  },
  {
    name: "invite-token",
    path: "/invite/:token()",
    meta: _91token_934nZC2wXiUSMeta || {},
    component: () => import("/tmp/build_38fa1549/pages/invite/[token].vue").then(m => m.default || m)
  },
  {
    name: "journeys-id",
    path: "/journeys/:id()",
    component: () => import("/tmp/build_38fa1549/pages/journeys/[id].vue").then(m => m.default || m)
  },
  {
    name: "journeys",
    path: "/journeys",
    component: () => import("/tmp/build_38fa1549/pages/journeys/index.vue").then(m => m.default || m)
  },
  {
    name: "myteam",
    path: "/myteam",
    component: () => import("/tmp/build_38fa1549/pages/myteam.vue").then(m => m.default || m),
    children: [
  {
    name: "myteam-members",
    path: "members",
    component: () => import("/tmp/build_38fa1549/pages/myteam/members.vue").then(m => m.default || m)
  },
  {
    name: "myteam-policy",
    path: "policy",
    component: () => import("/tmp/build_38fa1549/pages/myteam/policy.vue").then(m => m.default || m)
  },
  {
    name: "myteam-validators",
    path: "validators",
    component: () => import("/tmp/build_38fa1549/pages/myteam/validators.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "new-user",
    path: "/new-user",
    meta: new_45usermioX2Bp9pHMeta || {},
    component: () => import("/tmp/build_38fa1549/pages/new-user.vue").then(m => m.default || m)
  },
  {
    name: "password",
    path: "/password",
    meta: passwordzjrVWmszBoMeta || {},
    component: () => import("/tmp/build_38fa1549/pages/password.vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/tmp/build_38fa1549/pages/search.vue").then(m => m.default || m),
    children: [
  {
    name: "search-car-search_id",
    path: "car/:search_id()",
    component: () => import("/tmp/build_38fa1549/pages/search/car/[search_id]/index.vue").then(m => m.default || m)
  },
  {
    name: "search-car",
    path: "car",
    component: () => import("/tmp/build_38fa1549/pages/search/car/index.vue").then(m => m.default || m)
  },
  {
    name: "search-car-init-search_id",
    path: "car/init/:search_id()",
    component: () => import("/tmp/build_38fa1549/pages/search/car/init/[search_id].vue").then(m => m.default || m)
  },
  {
    name: "search-flight-search_id",
    path: "flight/:search_id()",
    component: () => import("/tmp/build_38fa1549/pages/search/flight/[search_id].vue").then(m => m.default || m)
  },
  {
    name: "search-flight",
    path: "flight",
    component: () => import("/tmp/build_38fa1549/pages/search/flight/index.vue").then(m => m.default || m)
  },
  {
    name: "search-hotel-search_id",
    path: "hotel/:search_id()",
    component: () => import("/tmp/build_38fa1549/pages/search/hotel/[search_id]/index.vue").then(m => m.default || m)
  },
  {
    name: "search-hotel-search_id-rooms-hotelIds",
    path: "hotel/:search_id()/rooms/:hotelIds()",
    component: () => import("/tmp/build_38fa1549/pages/search/hotel/[search_id]/rooms/[hotelIds].vue").then(m => m.default || m)
  },
  {
    name: "search-hotel",
    path: "hotel",
    component: () => import("/tmp/build_38fa1549/pages/search/hotel/index.vue").then(m => m.default || m)
  },
  {
    name: "search-train-search_id",
    path: "train/:search_id()",
    component: () => import("/tmp/build_38fa1549/pages/search/train/[search_id].vue").then(m => m.default || m)
  },
  {
    name: "search-train",
    path: "train",
    component: () => import("/tmp/build_38fa1549/pages/search/train/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: teams1jLrMaETEdMeta?.name,
    path: "/teams",
    meta: teams1jLrMaETEdMeta || {},
    component: () => import("/tmp/build_38fa1549/pages/teams.vue").then(m => m.default || m),
    children: [
  {
    name: _91teamId_937Cbmcr169iMeta?.name,
    path: ":teamId()",
    component: () => import("/tmp/build_38fa1549/pages/teams/[teamId].vue").then(m => m.default || m),
    children: [
  {
    name: "teams-teamId",
    path: "",
    component: () => import("/tmp/build_38fa1549/pages/teams/[teamId]/index.vue").then(m => m.default || m)
  },
  {
    name: "teams-teamId-invitation",
    path: "invitation",
    component: () => import("/tmp/build_38fa1549/pages/teams/[teamId]/invitation.vue").then(m => m.default || m)
  },
  {
    name: "teams-teamId-policy",
    path: "policy",
    component: () => import("/tmp/build_38fa1549/pages/teams/[teamId]/policy.vue").then(m => m.default || m)
  },
  {
    name: "teams-teamId-validators",
    path: "validators",
    component: () => import("/tmp/build_38fa1549/pages/teams/[teamId]/validators.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "teams",
    path: "",
    component: () => import("/tmp/build_38fa1549/pages/teams/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "train-id-cancel",
    path: "/train/:id()/cancel",
    component: () => import("/tmp/build_38fa1549/pages/train/[id]/cancel/index.vue").then(m => m.default || m)
  },
  {
    name: "train-id-exchange-changeable-selection",
    path: "/train/:id()/exchange/changeable-selection",
    component: () => import("/tmp/build_38fa1549/pages/train/[id]/exchange/changeable-selection/index.vue").then(m => m.default || m)
  },
  {
    name: "train-id-exchange-section-selection",
    path: "/train/:id()/exchange/section-selection",
    component: () => import("/tmp/build_38fa1549/pages/train/[id]/exchange/section-selection/index.vue").then(m => m.default || m)
  },
  {
    name: "train-id-exchange-train-selection",
    path: "/train/:id()/exchange/train-selection",
    component: () => import("/tmp/build_38fa1549/pages/train/[id]/exchange/train-selection/index.vue").then(m => m.default || m)
  },
  {
    name: "train-id",
    path: "/train/:id()",
    component: () => import("/tmp/build_38fa1549/pages/train/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93cN5BrZJ8kmMeta?.name,
    path: "/users/:id()",
    component: () => import("/tmp/build_38fa1549/pages/users/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "users-id-driver-license",
    path: "driver-license",
    component: () => import("/tmp/build_38fa1549/pages/users/[id]/driver-license.vue").then(m => m.default || m)
  },
  {
    name: "users-id-fidelity",
    path: "fidelity",
    component: () => import("/tmp/build_38fa1549/pages/users/[id]/fidelity.vue").then(m => m.default || m)
  },
  {
    name: "users-id",
    path: "",
    component: () => import("/tmp/build_38fa1549/pages/users/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "users-id-passport",
    path: "passport",
    component: () => import("/tmp/build_38fa1549/pages/users/[id]/passport.vue").then(m => m.default || m)
  },
  {
    name: "users-id-password",
    path: "password",
    component: () => import("/tmp/build_38fa1549/pages/users/[id]/password.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/tmp/build_38fa1549/pages/users/index.vue").then(m => m.default || m)
  },
  {
    name: "vouchers",
    path: "/vouchers",
    component: () => import("/tmp/build_38fa1549/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "teams-vouchers",
    path: "/teams-vouchers",
    component: () => import("/tmp/build_38fa1549/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: component_45stub5QgS0k4WJEMeta?.name,
    path: "/metrics",
    component: component_45stub5QgS0k4WJE
  },
  {
    name: component_45stub5QgS0k4WJEMeta?.name,
    path: "/en",
    component: component_45stub5QgS0k4WJE
  },
  {
    name: component_45stub5QgS0k4WJEMeta?.name,
    path: "/fr",
    component: component_45stub5QgS0k4WJE
  },
  {
    name: component_45stub5QgS0k4WJEMeta?.name,
    path: "/pt-BR",
    component: component_45stub5QgS0k4WJE
  },
  {
    name: component_45stub5QgS0k4WJEMeta?.name,
    path: "/en/:pathMatch(.*)",
    component: component_45stub5QgS0k4WJE
  },
  {
    name: component_45stub5QgS0k4WJEMeta?.name,
    path: "/fr/:pathMatch(.*)",
    component: component_45stub5QgS0k4WJE
  },
  {
    name: component_45stub5QgS0k4WJEMeta?.name,
    path: "/pt-BR/:pathMatch(.*)",
    component: component_45stub5QgS0k4WJE
  }
]